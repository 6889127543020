<template>
    <CRow class="justify-content-center">
        <CModal title="Aviso!" color="danger" :show.sync="showAlert">
            {{ msg }}
            <template #footer>
                <CButton @click="showAlert = false" color="danger">OK</CButton>
            </template>
        </CModal>
        <CCol sm="12">
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol sm="12">
                            <h4 id="traffic" class="card-title mb-0">Transfêrencia</h4>
                            <div class="small text-muted"></div>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    <CRow>
                        <CCol sm="12" md="6" lg="4">
                            <CWidgetDropdown 
                                color="warning " 
                                header="Saldo Disponível" >
                                <div slot="footer" class="card-body pb-4 justify-content-between">                                   
                                     <h1>  {{ 'R$ ' + String(resume.available.toFixed(2)).replace('.',',') }}</h1>
                                </div>
                            </CWidgetDropdown>
                        </CCol>
                    </CRow>
                    <CRow class="align-items-center">
                        <CCol sm="12">
                            <CRow>
                                <CCol sm="6" md="4">
                                    <CButton color="primary" variant="ghost" @click="wizardTransfer=0;idAccount=-1" :disabled="(wizardTransfer>=0)">Solicitar nova tranferência</CButton>
                                </CCol>
                            </CRow>
                            <CRow v-if="wizardTransfer>=0" class="mt-3">
                                <CCol sm="12">
                                    <CRow>
                                        <CCol sm="12">
                                            <h5 class="mb-3 ml-3">Tipo de transferência:</h5>
                                        </CCol>
                                        <CCol sm="12">
                                            <CRow form class="form-group" style="max-width: 600px;">
                                                <CInputRadioGroup
                                                    class="col-sm-12 ml-5"
                                                    :options="tpTransfer"
                                                    @update:checked="selectTpTransfer"
                                                    :style=" wizardTransfer>0 ? 'pointer-events: none;':''"
                                                />
                                            </CRow>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                            <CRow v-if="wizardTransfer>=1" class="mt-3">                                
                                <CCol v-if="tpTransferValue==='ted'" sm="12">
                                    <CRow>
                                        <CCol sm="12">
                                            <h5 class="mb-3 ml-3">Selecione uma conta de destino:</h5>
                                            <h6 class="mb-5 ml-3" v-if="accountsList.length===0">Nenhuma conta cadastrada</h6>
                                        </CCol>
                                        <CCol sm="12">
                                            <CRow form class="form-group" style="max-width: 600px;">
                                                <CInputRadioGroup
                                                    class="col-sm-12 ml-5"
                                                    :options="accountsList"
                                                    @update:checked="selectAccount"
                                                    :style="idAccount>=0 ? 'pointer-events: none;':''"
                                                />
                                            </CRow>
                                        </CCol>
                                    </CRow>
                                </CCol>                               
                                <CCol v-if="tpTransferValue==='pix'" sm="12">
                                    <CRow>
                                        <CCol sm="12">
                                            <h5 class="mb-3 ml-3">Selecione uma conta de destino:</h5>
                                            <h6 class="mb-5 ml-3" v-if="accountsPixList.length===0">Nenhuma conta cadastrada</h6>
                                        </CCol>
                                        <CCol sm="12">
                                            <CRow form class="form-group-pix" style="max-width: 600px;">
                                                <CInputRadioGroup
                                                    class="col-sm-12 ml-5"
                                                    :options="accountsPixList"
                                                    @update:checked="selectAccount"
                                                    :style="idAccount>=0 ? 'pointer-events: none;':''"
                                                />
                                            </CRow>
                                        </CCol>
                                    </CRow>
                                </CCol>
                            </CRow>
                            <CRow v-if="wizardTransfer>=2" class="mt-3">
                                <CCol sm="12" lg="6">
                                    <CInput 
                                        label="Valor:" 
                                        class="ml-3"  
                                        v-model="vlrTranfer" 
                                        v-mask-decimal.br="2"
                                        placeholder="0,00"
                                        :disabled="validation"
                                    >
                                        <template #append>
                                            <CButton type="submit" color="primary" @click="getToken" :disabled="validation">Validar</CButton>
                                        </template>
                                    </CInput>
                                </CCol>
                            </CRow>
                            <CRow v-if="wizardTransfer>=3" class="mt-3">
                                <CCol sm="12">
                                    <h6 class="mb-3 ml-3">Token enviado via <b>SMS</b> para o celular <b>{{phone_mask}}</b></h6>
                                </CCol>
                                <CCol sm="12" md="6" lg="4">
                                    <CInput 
                                        label="Token:" 
                                        class="ml-3"
                                        v-model="token"
                                    />
                                </CCol>
                                <CCol sm="12" md="6" lg="4">
                                    <vue-ellipse-progress 
                                        v-if="resendToken===false"
                                        :progress="( 100 / progressStart) * progress"
                                        :thickness="6"
                                        :legendValue="progress"
                                        :size="70"
                                    />
                                    <CButton v-else color="primary" @click="getToken" class="mt-4">Reenviar Token</CButton>
                                </CCol>
                                <CCol sm="12">                                    
                                    <CButton v-if="validation" color="primary" @click="fetchTransfer">Confirmar</CButton>
                                </CCol>  
                            </CRow>
                            <CRow v-if="wizardTransfer===-1" class="mt-3">
                                <CCol sm="12">
                                    <CDataTable
                                        class="mb-0 table-outline"
                                        hover
                                        head-color="light"
                                        pagination                                   
                                        :items="transfer"
                                        :fields="tableFields"
                                        :noItemsView="noItemsView"
                                        :items-per-page="6"
                                    >
                                        <td slot="bank" slot-scope="{item}">{{item.account_destination[0].bank}} - {{getNameBank(item.account_destination[0].bank)}}</td>
                                        <td slot="branch" slot-scope="{item}">{{item.account_destination[0].branch}}-{{item.account_destination[0].branch_verifying_digit}}</td>
                                        <td slot="account" slot-scope="{item}">{{item.account_destination[0].account}}-{{item.account_destination[0].account_verifying_digit}}</td>
                                        <td slot="total_amount" slot-scope="{item}">
                                            R$ {{item.total_amount.toFixed(2).replace('.',',')}}
                                        </td>
                                        <td slot="transaction_date" slot-scope="{item}">
                                            {{(new Date(item.transaction_date)).toLocaleDateString()}}
                                        </td>
                                        <td slot="transaction_time" slot-scope="{item}">
                                            {{((new Date(item.transaction_date)).toLocaleString('pt-BR', { timeZone: 'America/Recife' })).split(' ')[1] }}
                                        </td>
                                        <template slot="transaction_status" slot-scope="{item}">
                                          <td v-if="item.transaction_status=='REQUESTING'"><CBadge color="info" class="mfs-auto">Enviando...</CBadge></td>
                                          <td v-else-if="item.transaction_status=='CREATED'"><CBadge color="warning" class="mfs-auto"> Aguardando </CBadge></td>
                                          <td v-else-if="item.transaction_status=='APPROVED'"><CBadge color="primary" class="mfs-auto"> Aprovado </CBadge></td>
                                          <td v-else-if="item.transaction_status=='FAIL'"><CBadge color="danger" class="mfs-auto"> Negado </CBadge></td>
                                          <td v-else-if="item.transaction_status=='CANCELED'"><CBadge color="danger" class="mfs-auto"> Cancelado </CBadge></td>
                                          <td v-else><CBadge color="danger" class="mfs-auto">{{item.transaction_status}}</CBadge></td>
                                        </template>
                                        <td slot="proof" slot-scope="{item}">
                                            <CButton
                                                size="sm"
                                                color="info"                                                
                                                :name=item.id
                                                :key="'ed'+item.id"
                                            >
                                                <CIcon size="sm" name="cil-file" />
                                            </CButton>
                                        </td>
                                    </CDataTable>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CRow>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
import functions from "@/functions";
const axios = require("axios").default;

export default {
    name:'',
    component:{},
    data(){
        return{
            showAlert: false,
            msg: '',
            wizardTransfer: -1,
            vlrTranfer: 0.0,
            idAccount: -1,
            resume:{
                real: 0,
                blocked: 0,
                available: 0,
            },
            transfer:[],
            acTranfer:[],
            tableFields:  [
                { key: 'transaction_date', label: 'Data'  },
                { key: 'transaction_time', label: 'Hora'  },
                { key: 'total_amount', label: 'Valor'  },
                { key: 'bank', label: 'Banco'  },
                { key: 'branch', label: 'Agência'  },
                { key: 'account', label: 'Conta'  },
                { key: 'transaction_status', label: 'Status' },
            ],
            noItemsView:{ 
                noResults: 'Nenhum resultado para este filtro', 
                noItems: 'Nenhuma movimentação de saque' 
            },
            accounts:[],
            accountsList:[],
            progress: 300,
            progressStart: 300,
            validation: false,
            phone_mask: '',
            token:'',
            resendToken: false,
            tpTransfer: [{
                value: 'pix',
                label: 'Via Pix'
            }
            //,{
            //    value: 'ted',
            //    label: 'Por TED'
            //}
            ],
            tpTransferValue: '',
            accountsPixList:[],
        }
    },
    methods:{
        ...functions,
        getData(){
            const token = localStorage.getItem('user-token');
            axios({
                method: 'get',
                url: this.vhost('client/balance'),
                headers: { "Content-Type": "application/json","x-access-token": token},
            })
            .then((res) => {
                if (res.data.data.resume){
                this.resume=res.data.data.resume;
                }
            })
            .catch(this.onError);
        },
        getAcounts(){
           const token = localStorage.getItem('user-token');
            axios({
                method: 'get',
                url: this.vhost('client/accountperson'),
                headers: { "Content-Type": "application/json","x-access-token": token},
            })
            .then((res) => {
                this.accounts = res.data.data;
                console.log(this.accounts)
                this.accountsList = this.accounts.map(e=> {
                    if (e.accountdestinationpix && e.accountdestinationpix[0] && e.accountdestinationpix[0].name){
                        this.accountsPixList.push({
                            value: e.id,
                            label: `Banco ${this.getNameBank(e.bank)}. 
                                    Agência: ${e.branch}-${e.branch_verifying_digit}.
                                    Conta: ${e.account}-${e.account_verifying_digit}.
                                    Tipo: ${e.account_type_destination}.
                                    Titular: ${e.person_type==='CORPORATE'? 
                                        e.company[0].company_name + ' CNPJ: ' + this.formatCNPJ(e.company[0].cnpj) : 
                                        e.representative[0].name + ' CPF: ' + this.formatCPF(e.representative[0].tax_id)}`,
                        })
                    }
                    return {
                        value: e.id,
                        label: `Banco ${this.getNameBank(e.bank)}. 
                                Agência: ${e.branch}-${e.branch_verifying_digit}.
                                Conta: ${e.account}-${e.account_verifying_digit}.
                                Tipo: ${e.account_type_destination}.
                                Titular: ${e.person_type==='CORPORATE'? 
                                    e.company[0].company_name + ' CNPJ: ' + this.formatCNPJ(e.company[0].cnpj) : 
                                    e.representative[0].name + ' CPF: ' + this.formatCPF(e.representative[0].tax_id)}`,
                    }
                });
                

                
            })
            .catch(this.onError);
        },
        getTranfers(){            
            const token = localStorage.getItem('user-token');
            axios({
                method: 'get',
                url: this.vhost('client/persontransfer'),
                headers: { "Content-Type": "application/json","x-access-token": token},
            })
            .then((res) => {
                this.transfer = res.data.data;
            })
            .catch(this.onError);
        },
        selectAccount(param){
            this.idAccount=param;
            this.wizardTransfer++;
        },
        selectTpTransfer(param){
            console.log(param)
            this.tpTransferValue=param;
            this.wizardTransfer++;
        },
        fetchTransfer(){
            const token = localStorage.getItem('user-token');
            const data = {
                id: this.idAccount,
                vlr_transfer: this.vlrTranfer,
                token: this.token
            }
            this.$loading(true);
            axios({
                method: 'post',
                url: this.vhost(this.tpTransferValue==='ted'?'client/persontransfer':'client/persontransfer/instantpayment'),
                headers: { "Content-Type": "application/json","x-access-token": token},
                data
            })
            .then(() => {
                this.$loading(false);
                this.cancelTransfer();
                this.getTranfers();
            })
            .catch(err=> {
                this.$loading(false);
                if(err.response.status===400){
                    this.cancelTransfer();
                    this.getTranfers();
                }
                this.onError(err);
            });
        },
        cancelTransfer(){
            this.wizardTransfer=-1;
            this.validation=false
            this.vlrTranfer=0.0;
            this.progress=0
        },
        getToken(){
            this.token='';
            this.resendToken=false;
            const cValue = Number(this.vlrTranfer.replace('.','').replace('.','').replace(',','.'));
            this.validation= ( cValue > 0) && ( cValue <= this.resume.available);
            if (!this.validation){
                return
            }
            const token = localStorage.getItem('user-token');
            axios({
                method: 'post',
                url: this.vhost('client/token'),
                headers: { "Content-Type": "application/json","x-access-token": token},
                data:{id_account: this.idAccount}
            })
            .then((con)=>{
                if (this.wizardTransfer < 3){
                    this.wizardTransfer++;
                }
                this.phone_mask = con.data.data.phone_mask;
                this.progress= con.data.data.expires_in;
                this.progressStart=this.progress;
                this.timer();
            })
            .catch(this.onError)
        },
        timer(){
            setTimeout(()=>{
                this.progress--
                try {
                    if (this.progress > 0){
                        this.timer()
                    } else {
                        this.resendToken=true;
                    }
                } catch (error) {
                    this.progress=0;
                }
            }, 1000)
        },
        onError(err){
            if (err.response && err.response.data && err.response.data.auth===false) {
                localStorage.setItem('user-token', '');
                this.$router.push('/login');
            } else if (err.response && err.response.data && err.response.data.erro){
                this.setAlert(err.response.data.erro);        
            } else {
                this.setAlert(err.message);
            }  
        },
        setAlert: function (message){
            this.msg=message;
            this.showAlert=(message !== undefined);
        },
    },
    created(){
        this.getData();
        this.getAcounts();
        this.getTranfers();
    },
}
</script>

<style>

</style>